<template>
  <div class="inventoryLabResultCreate">
    <v-breadcrumbs>
      <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
        >Home</v-breadcrumbs-item
      >
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :exact="true"
        :to="{ name: 'inventoryLabResult', params: {} }"
      >
        Inventory Lab Result
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
      <v-breadcrumbs-item
        :to="{ name: 'inventoryLabResultUpload', params: {} }"
      >
        Upload
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card class="py-10">
      <v-card-title>
        Lab Result Upload
        <v-spacer />
        <v-btn icon small @click="dialog = true">
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-file-input
          accept="text/csv"
          :rules="
            (value) =>
              !value || value.type == 'text/csv' || 'Only CSV files allowed'
          "
          label="Choose a lab result (CSV format)"
          v-model="csvFile"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="goBack"> Back </v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="loading" @click="uploadLabResults">
          Upload
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>CSV File Format Description</v-card-title>
        <v-card-text>
          The expected CSV file format is as follows:
          <ul>
            <li>
              It should have columns named either "Date" or "Analysis Date" for
              the date.
            </li>
            <li>A column named "Time" for the time.</li>
            <li>
              A column named either "AverOutN" or "Charge" for the document
              number.
            </li>
            <li>
              Additional columns named "Pb", "Sn", "Sb", "Ca", "Al", "Bi", "Cu",
              "Ni", "As", "Ag", "Fe", "Zn", "Te", "Se", "S", and "Cd" for lab
              elements.
            </li>
            <li>
              Optionally, it can have columns named "SampleNo" or "Sample No.",
              "Quality", "Program", "QMDelete" or "Operator".
            </li>
            <li>
              The date should be in the format "DD/MM/YYYY", and the time should
              be in the format "HH:mm:ss".
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewDialog" max-width="800px">
      <v-card>
        <v-card-title>CSV Preview</v-card-title>
        <v-card-text>
          <v-alert v-if="futureDateFound" type="warning" dense>
            {{ warningMessage }}
          </v-alert>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th v-for="header in previewHeaders" :key="header">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in previewData" :key="row.id">
                  <td
                    class="nowrap"
                    v-for="header in previewHeaders"
                    :key="header"
                  >
                    <span v-if="header == 'testTime'">
                      {{ row[header] | formatDateTimeYear }}
                    </span>
                    <span v-else>
                      {{ row[header] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="primary" :disabled="futureDateFound" @click="confirmUpload" :loading="loading">
            Confirm and Upload
          </v-btn>
          <v-btn @click="previewDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import csv from "csvtojson";
import { mapActions } from "vuex";
// import axiosObj from "axios";
import moment from "moment";

export default {
  name: "inventoryLabResult",
  data: () => {
    return {
      csvFile: null,
      allElements: null,
      loading: false,
      dialog: false,
      preparedRows: [],
      previewDialog: false,
      previewData: [],
      previewHeaders: [],
      warningMessage: "",
      futureDateFound: false,
    };
  },
  created() {
    this.$emit("showParent", false);
    this.initLabElements();
  },
  methods: {
    ...mapActions("inventoryLabResult", [
      "createInventoryLabResult",
      "createAllInventoryLabResults",
    ]),
    ...mapActions("labElement", ["fetchLabElements"]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    initLabElements() {
      const params = {
        page: 0,
        size: 300,
      };
      this.fetchLabElements(params).then((response) => {
        this.allElements = response.content;
      });
    },
    uploadLabResults() {
      var fileTypes = ["csv"]; //acceptable file types
      var extension = this.csvFile.name.split(".").pop().toLowerCase(), //file extension from input file
        isAcceptable = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

      if (!isAcceptable) {
        this.addErrorMessage(
          "Invalid file detected, please choose the .csv file exported from the lab"
        );
        return;
      }

      var reader = new FileReader();

      reader.onload = () => {
        try {
          const csvStr = reader.result;

          csv()
            .fromString(csvStr)
            .then((csvRows) => {
              const filteredRows = csvRows.filter((row) => {
                const date = row.Date ? row.Date : row["Analysis Date"];
                const time = row.Time;
                const documentNo = row.AverOutN ? row.AverOutN : row.Charge;

                return date && time && documentNo;
              });

              // Prepare the rows to be uploaded and show in the preview
              const preparedRows = filteredRows.map(this.mapRows);
              const { previewData, previewHeaders } =
                this.generatePreviewDataAndHeaders(preparedRows);

              this.preparedRows = preparedRows;
              this.previewData = previewData;
              this.previewHeaders = previewHeaders;
              this.previewDialog = true;
            });
        } catch (e) {
          this.addErrorMessage("Error parsing CSV file.");
        }
      };

      reader.readAsBinaryString(this.csvFile);
    },
    generatePreviewDataAndHeaders(preparedRows) {
      let previewData = [];
      const currentTime = moment();
      this.warningMessage = ""; // Reset warning message

      preparedRows.forEach((row, index) => {
        let flattenedRow = { "Row Number": index + 1 };

        if (row.averoutN) {
          flattenedRow["Document No."] = row.averoutN;
        }

        for (const [key, value] of Object.entries(row)) {
          if (key !== "elements" && key !== "averoutN") {
            flattenedRow[key] = value;
          }
        }

        row.elements.forEach((element) => {
          flattenedRow[element.element] = element.compositionPercent;
        });

        if (moment(row.testTime).isAfter(currentTime)) {
          this.warningMessage += `Row number ${
            index + 1
          } has a future test time. `;
          this.futureDateFound = true;
        }

        previewData.push(flattenedRow);
      });

      const previewHeaders = Object.keys(previewData[0]);

      return {
        previewData,
        previewHeaders,
      };
    },
    confirmUpload() {
      this.loading = true;

      // Upload the prepared rows in previewData
      this.uploadRows(this.preparedRows)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.addErrorMessage("Error uploading file");
          this.loading = false;
        });
      this.previewDialog = false;
    },
    mapRows(row) {
      const date = row.Date ? row.Date : row["Analysis Date"];
      const time = row.Time;
      const currentYear = new Date().getFullYear();
      let dateTime = moment(`${date} ${time}`, "DD/MM/YYYY HH:mm:ss");

      const rowYear = dateTime.year();
      if (rowYear - currentYear > 500) {
        dateTime = dateTime.subtract(543, "years");
      }

      let fields = [
        "Pb",
        "Sn",
        "Sb",
        "Ca",
        "Al",
        "Bi",
        "Cu",
        "Ni",
        "As",
        "Ag",
        "Fe",
        "Zn",
        "Te",
        "Se",
        "S",
        "Cd",
      ];

      const elements = fields.map((f) => {
        const id = this.getElementId(f.toLowerCase());
        const element = f;
        const numb = row[f];
        let lessThan = false;
        if (numb.includes("<")) {
          lessThan = true;
        }
        const parsed = parseFloat(numb.replace("<", ""));
        return {
          labElementId: id,
          element,
          compositionPercent: parsed,
          lessThan,
        };
      });

      return {
        sampleName: row.SampleNo ? row.SampleNo : row["Sample No."],
        quality: row.Quality,
        averoutN: row.AverOutN ? row.AverOutN : row.Charge,
        program: row.Program,
        technician: row.QMDelete ? row.QMDelete : row.Operator,
        elements,
        testTime: dateTime.format("YYYY-MM-DDTHH:mm:ss"),
      };
    },
    getElementId(symbol) {
      const match = this.allElements.find((e) => e.symbol == symbol);
      return match ? match.id : null;
    },
    async uploadRows(rows) {
      if (rows && rows.length > 0) {
        // Remove the 'element' property from each element in each row
        const modifiedRows = rows.map(row => {
          const elements = row.elements.map(({ element, ...rest }) => rest);
          return { ...row, elements };
        });

        this.createAllInventoryLabResults(modifiedRows)
          .then((response) => {
            let counter = 0;
            counter = response.length;
            let skippedCount = rows.length - counter;

            if (skippedCount > 0) {
              this.addMessage(
                `Uploaded ${counter} rows successfully with ${skippedCount} rows skipped.`
              );
            } else {
              this.addSuccessMessage("Uploaded " + counter + " rows");
            }
            this.goBack();
          })
          .catch((err) => {
            console.error(err);
            this.addErrorMessage("Error uploading lab results");
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
}
</style>